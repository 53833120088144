@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');


.section-one{
  display: flex;
  justify-content: space-evenly;
  margin-top: 130px;
}

.section-one .section-one-container{
  width: 45%;
}

.section-one .section-one-one{
  width: 100%;
}

.title-one{
  font-family: "Rubik", sans-serif;
  color: #03b3f1;
  font-size: 40px;
  text-align: center;
}

.historia{
  font-family: sans-serif;
  font-size: 20px;
}

.section-one .section-one-two{
  width: 85%;
}

.title-two{
  font-family: "Rubik", sans-serif;
  color: #03b3f1;
  font-size: 30px;
  text-align: left;
}

.mision, .vision{
  font-family: sans-serif;
  font-size: 20px;
}

.figura{
  justify-content: center;
  width: 45%;
}

.imagen{
  width: 600px;
}

@media (max-width: 1024px) {
    .section-one{
        display: block;
    }

    .section-one .section-one-container{
        width: 90%;
        margin: 0 auto;
    }
      
      .section-one .section-one-one{
        width: 100%;
      }

      .figura-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto; /* Ajusta el margen según sea necesario */
      }
    

      .imagen{
        animation: shrinkAnimation 2s forwards; /* Animación para reducir el tamaño */
    }
    
    @keyframes shrinkAnimation {
        0% {
            transform: scale(1, 1); /* Tamaño completo */
        }
        100% {
            transform: scale(0.6, 0.6); /* Tamaño reducido */
        }
    }

}