@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
    text-align: center;
    font-family: "Rubik", sans-serif;
}
  
.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
}
  
.footer-section {
    flex: 1;
    min-width: 200px;
    margin: 10px;
}

/* Centrado de la lista */
.footer-section.links {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    text-align: center;
}

.footer-section ul li {
    margin: 5px 0;
}

.footer-section h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: rgb(4, 144, 245);
}
  
.footer-section p, .footer-section a {
    color: white;
    font-size: 14px;
    text-decoration: none;
}
  
.footer-section a:hover {
    color: rgb(118, 198, 255);
}
  
.social-links {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.footer-bottom {
    border-top: 1px solid #777;
    padding-top: 10px;
    font-size: 14px;
    color: white;
}

.footer-bottom .derechos {
    font-size: 14px;
}
