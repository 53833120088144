.servicios{
    margin-top: 130px;
    display: flex; /* Cambia a flex para centrar contenido */
    flex-direction: column; /* Asegura que las secciones estén en columna */
    align-items: center; /* Centra horizontalmente el contenido */
}

.servicios .section-servicios{
    width: 60%;
    
}

.section-servicios-title {
    margin-bottom: 20px;
    font-family: "Rubik", sans-serif;
    font-size: 40px;
    color: #03b3f1;
}

.text-description{
    font-size: 20px;
    font-family: sans-serif;
}

.card-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 40px; /* Espacio entre secciones */
}

.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 30%; /* Ajustar el ancho de las tarjetas */
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.text-description-card{
    font-size: 18px;
    font-family: sans-serif;
    text-align: left;
}

.title-card{
    font-family: "Rubik", sans-serif;
    font-size: 25px;
}

.img-plan1, .img-plan2 {
    max-width: 50%; /* Hace que la imagen sea responsive */
    height: auto;
    border-radius: 8px 8px 0 0; /* Bordes superiores redondeados */
}

.card button {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.card button:hover {
    background-color: #0056b3;
}

@media (max-width: 1100px) {
    .card-container {
        /* display: flex;
        flex-direction: column;
        align-items: center; */
    }
    .card {
        width: 40%; /* Las tarjetas ocuparán todo el ancho */
        margin-bottom: 20px; /* Espacio entre tarjetas */
    }

    .img-plan1, .img-plan2 {
        max-width: 80%; /* Hace que la imagen sea responsive */
    }
}

@media (max-width: 769px) {
    .card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .card {
        width: 60%; /* Las tarjetas ocuparán todo el ancho */
        margin-bottom: 20px; /* Espacio entre tarjetas */
    }

    .img-plan1, .img-plan2 {
        max-width: 80%; /* Hace que la imagen sea responsive */
    }

    .text-description-card{
        font-size: 14px;
        font-family: sans-serif;
        text-align: left;
    }
    
    .title-card{
        font-family: "Rubik", sans-serif;
        font-size: 18px;
    }
}