.section-catalogo{
    margin-top: 130px;
    
    /* display: grid;
    min-height: 100dvh;
    grid-template-rows: auto 1fr auto; */
}

.section-catalogo .section-catalogo-h1{
    text-align: center;
    height: 100vh;
}