/* TypingText.css */
.fullscreen-section {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative; /* Necesario para posicionar el video de fondo */
}

.fullscreen-section video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Envía el video al fondo para que el texto esté en la capa superior */
}

.typing-text h1 {
  font-family: "Rubik", sans-serif;
  font-size: 2.5em;
  color: #fff;
  white-space: nowrap;
  z-index: 1; /* Asegura que el texto esté sobre el video */
}

@media (max-width: 450px){
  .typing-text h1 {
    font-family: "Rubik", sans-serif;
    font-size: 2em;
  }
  
}