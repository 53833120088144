@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.section-job{
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.section-job .section-job-container{
  width: 45%;
}

.section-job-img1{
  width: 45%;
}

.foto1{
  width: 100%;
}

.title1{
  font-family: "Rubik", sans-serif;
  color: #03b3f1;
  font-size: 40px;
  text-align: center;
}

@media (max-width: 1100px) {
  .section-job{
    display: flex;
    flex-direction: column;
  }

  .section-job .section-job-container{
    width: 90%;
  }
  
  .section-job-img1{
    width: 90%;
  }

}