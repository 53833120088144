@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.navbar {
    position: fixed; /* Mantiene la barra de navegación en la parte superior al hacer scroll */
    top: 0;
    left: 0;
    width: 99%;
    background: white;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Rubik", sans-serif;
    padding: 0 100px 0 5px;
    z-index: 1000; /* Asegura que esté por encima del contenido */
    -webkit-box-shadow: 0px -1px 24px -1px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px -1px 24px -1px rgba(0,0,0,0.16);
    box-shadow: 0px -1px 24px -1px rgba(0,0,0,0.16);
}

.logo {
    width: 100px;
    transform: scale(1, 1); /* Tamaño inicial normal */
    transition: transform 2s; /* Suaviza la transición entre estados */
}

.nav_items {
    display: flex;
    gap: 20px;
    align-items: center;
    transition: transform 0.4s ease, opacity 0.3s ease; /* Solo transiciones aquí */
    transform: translateX(0);
    opacity: 1;
}

.nav_items.open {
    transform: translateX(0); /* No es necesario si se inicia con translateX(0) */
    opacity: 1;
    visibility: visible;
}

.nav_items a {
    color: black;
    font-size: 20px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.nav_items a:hover {
    color: rgb(4, 144, 245); /* Un pequeño efecto al pasar el mouse */
}

.nav_toggle {
    display: none;
}

/* Para pantallas pequeñas */
@media (max-width: 768px) {
    .logo {
        animation: shrinkAnimation 2s forwards; /* Animación para reducir el tamaño */
    }
    
    @keyframes shrinkAnimation {
        0% {
            transform: scale(1, 1); /* Tamaño completo */
        }
        100% {
            transform: scale(0.7, 0.7); /* Tamaño reducido */
        }
    }

    .nav_items {
        position: fixed;
        top: 120px;
        right: 0;
        background: white;
        flex-direction: column;
        width: 50%;
        height: calc(100vh - 120px);
        justify-content: start;
        align-items: center;
        transform: translateX(100%); /* Ocultar inicialmente */
        opacity: 0; /* Ocultar inicialmente */
        visibility: hidden; /* Ocultar inicialmente */
        z-index: 1000;
        transition: transform 0.4s ease, opacity 0.3s ease; /* Solo transiciones aquí */
        -webkit-box-shadow: -2px 24px 24px -1px rgba(0,0,0,0.16);
        -moz-box-shadow: -2px 24px 24px -1px rgba(0,0,0,0.16);
        box-shadow: -2px 24px 24px -1px rgba(0,0,0,0.16);
    }

    .nav_items.open {
        transform: translateX(0);
        transition: transform 0.4s ease, opacity 0.3s ease;
        opacity: 1;
        visibility: visible;
    }

    .nav_toggle {
        display: flex !important;
        flex-direction: column;
        cursor: pointer;
        margin: 15px;
    }

    .nav_toggle span {
        width: 30px;
        height: 4px;
        background: black;
        margin-bottom: 5px;
        border-radius: 2px;
        transform-origin: 5px 0px;
        transition: all 0.2s linear;
    }

    .nav_toggle.open > span {
        transform: rotate(45deg) translate(0px, 0px);
    }

    .nav_toggle.open > span:nth-child(2) {
        display: none;
    }

    .nav_toggle.open > span:nth-child(3) {
        transform: rotate(-45deg) translate(-5px, 1px);
    }
}

/* Cuando vuelve a pantallas más grandes */
@media (min-width: 769px) {
    .logo {
        animation: growAnimation 2s forwards; /* Animación para volver al tamaño original */
    }

    @keyframes growAnimation {
        0% {
            transform: scale(0.7, 0.7); /* Tamaño reducido */
        }
        100% {
            transform: scale(1, 1); /* Tamaño completo */
        }
    }
}